import React from "react";
import "../styles/formations.css";
import photo from "../assets/icons/blue.png";
import Navbar from "./Navbar";
import Footer from "./Footer";
import cardImage1 from "../assets/images/airport.webp";
import cardImage2 from "../assets/images/restau.png";
import cardImage3 from "../assets/images/peche.png";
import cardImage4 from "../assets/images/managers.png";
import cardImage5 from "../assets/images/hotel.webp";
import cardImage6 from "../assets/images/marketing.png";
import cardImage7 from "../assets/images/energy.png";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Formations() {
  const { t } = useTranslation("formations");

  // Assuming trainings are defined in JSON as an array
  const trainings = t("trainings", { returnObjects: true });

  const pdfLinks = [
    "/pdfs/aeroport.pdf",
    "/pdfs/RESTAURATION.pdf",
    "/pdfs/peche.pdf",
    "/pdfs/MANAGER.pdf",
    "/pdfs/hotelerie.pdf",
    "/pdfs/AI.pdf",
    "/pdfs/energy.pdf",
  ];
  const pdfEnLinks = [
    "/pdfs/aeroport.pdf",
    "/pdfs/RESTAURATION-en.pdf",
    "/pdfs/peche-en.pdf",
    "/pdfs/MANAGER-en.pdf",
    "/pdfs/Hotel-en.pdf",
    "/pdfs/AI-en.pdf",
    "/pdfs/energy-en.pdf",
  ];

  return (
    <div className="formaWrapper">
      <Navbar />
      <div className="page-container">
        <div className="text-section">
          <div className="title">{t("pageTitle")}</div>
          <p>{t("pageDescription")}</p>
        </div>
        <div className="image-section">
          <div className="circular-image">
            <img src={photo} alt={t("pageTitle")} />
          </div>
        </div>
      </div>

      <div className="title-section">{t("catalogTitle")}</div>

      {/* Cards Section */}
      <div className="trainings-container">
        {trainings.map((training, index) => (
          <div className="training-card" key={index}>
            <img
              src={
                [
                  cardImage1,
                  cardImage2,
                  cardImage3,
                  cardImage4,
                  cardImage5,
                  cardImage6,
                  cardImage7,

                ][index]
              }
              alt={training.title}
              className="training-image"
            />
            <div className="training-content">
              <p className="training-badge">{training.badge}</p>
              <h2 className="training-title">{training.title}</h2>{" "}
              <p className="training-description">{training.description}</p>
              <a
                href={pdfLinks[index]}
                target="_blank"
                rel="noopener noreferrer"
                className="training-link"
                id="download"
              >
                {training.title2}
              </a>
              <br></br>
              <a
                href={pdfEnLinks[index]}
                target="_blank"
                rel="noopener noreferrer"
                className="training-link"
                id="download"
              >
                {training.title3}
              </a>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
export default Formations;
