import React from "react";
import "../styles/about.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import team from "../assets/icons/team.png";
import exampleImage from "../assets/images/learn.png";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation("about");

  return (
    <div className="contain">
      <div className="about-wrapper">
        <Navbar />
        <div className="container">
          <div className="text-section">
            <div className="title" dangerouslySetInnerHTML={{ __html: t("title") }}></div>
            <div className="subtitle" dangerouslySetInnerHTML={{ __html: t("subtitle") }}></div>
            <p>{t("section1.paragraph")}</p>
          </div>
          <div className="image-section">
            <img src={team} alt={t("title")} />
          </div>
        </div>

        {/*Section 2*/}

        <div className="digiforma-section">
          <div className="digiforma-content">
            <div className="digiforma-image">
              <img src={exampleImage} alt="Illustration" />
            </div>
            <div className="digiforma-text">
              <h2 dangerouslySetInnerHTML={{ __html: t("section2.title") }}></h2>
              <p>{t("section2.paragraph1")}</p>
              <p>{t("section2.paragraph2")}</p>
            </div>
          </div>
          <div className="cta-section">
            <p dangerouslySetInnerHTML={{ __html: t("cta") }}></p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;