import React from "react";
import "../styles/elearning.css";
import learn from "../assets/images/learn.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ElearningSection = () => {
  const { t } = useTranslation("elearning");

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Enables smooth scrolling
    });
  };

  return (
    <div className="max-width">
      <section className="elearning-section">
        <div className="elearning-section__content-container">
          <div className="elearning-section__image-content">
            <img
              src={learn}
              alt={t("title")}
              className="elearning-section__illustration"
            />
          </div>
          <div className="elearning-section__text-content">
            <h2 className="elearning-section__title">
              <span>Edoceo </span>
              {t("title")}
              <br />
            </h2>
            <p>{t("description")}</p>
            <Link to="/formation" onClick={handleScrollToTop}>
              <button className="elearning-section__cta-button">
                {t("cta_button")}
              </button>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ElearningSection;