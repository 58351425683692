
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import JSON files for each language and namespace
import navbar_en from './locales/en/navbar.json';
import presentation_en from './locales/en/presentation.json';

import navbar_fr from './locales/fr/navbar.json';
import presentation_fr from './locales/fr/presentation.json';

import quality_en from './locales/en/quality.json';
import quality_fr from './locales/fr/quality.json';

import elearning_en from './locales/en/elearning.json';
import elearning_fr from './locales/fr/elearning.json';

import footer_en from './locales/en/footer.json';
import footer_fr from './locales/fr/footer.json';

import about_en from './locales/en/about.json';
import about_fr from './locales/fr/about.json';

import formations_en from './locales/en/formation.json';
import formations_fr from './locales/fr/formation.json';

import contact_en from './locales/en/contact.json';
import contact_fr from './locales/fr/contact.json';

import client_en from './locales/en/client.json'
import client_fr from './locales/fr/client.json'
// Initialize i18next with the translations from JSON files
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        navbar: navbar_en,
        presentation: presentation_en,
        quality: quality_en,
        elearning: elearning_en,
        footer: footer_en,
        about: about_en,     
        formations: formations_en,
        contact: contact_en,
        client: client_en

     },
      fr: {
        navbar: navbar_fr,
        presentation: presentation_fr,
        quality: quality_fr,
        elearning: elearning_fr,
        footer: footer_fr,
        about: about_fr,
        formations: formations_fr,
        contact: contact_fr,
        client: client_fr

      }
    },
    lng: "fr", // default language
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false // React already protects from XSS
    }
  });

export default i18n;