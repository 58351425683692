import React from "react";
import '../styles/clients.css'
import client1 from '../assets/icons/arthur-1.png'
import client2 from '../assets/images/client2.jpg'
import client3 from '../assets/images/client3.png'
import client4 from '../assets/images/client4.png'
import client5 from '../assets/images/client5.png'
import client6 from '../assets/images/client6.png'
import client7 from '../assets/images/client7.jpeg'
import client8 from '../assets/images/stenergy.png'


import { useTranslation } from "react-i18next";

const logos = [
    {
        id: 1,
        url: client1,
        alt: "client1",
      },
  {
    id: 2,
    url: client2,
    alt: "client2",
  },
  {
    id: 3,
    url: client3,
    alt: "client3",
  },
  {
    id: 4,
    url: client4,
    alt: "client4",
  },
  {
    id: 5,
    url: client5,
    alt: "client5",
  }, 
  {
    id: 6,
    url: client6,
    alt: "client6",
  },
  {
    id: 7,
    url: client7,
    alt: "client7",
  },
  {
    id: 8,
    url: client8,
    alt: "client8",
  },
];

const LogoCarousel = () => {
    const { t } = useTranslation("client");

  return (
    <div className="max-width">
      <div className="carousel-container">
        <div className="carousel-content">
          <h1 className="carousel-title">
            <span className="highlight-client">{t("clients_title")}</span>
          </h1>
         
          <div className="logo-track-container">
            <div className="logo-track">
              {[...logos, ...logos].map((logo, index) => (
                <div key={`${logo.id}-${index}`} className="logo-item">
                  <img src={logo.url} alt={logo.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;