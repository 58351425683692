import React from "react";
import "../styles/quality.css"; // You can use CSS modules or a CSS-in-JS solution.
import quality from "../assets/images/quality.svg";
import { useTranslation } from "react-i18next";

const Quality = () => {
  const { t } = useTranslation("quality");

  return (
    <div className="max-width">
      <section className="digiforma-section">
        <div className="content-container">
          <div className="text-content">
            <h2 className="title">{t("title")}</h2>
            <ul className="feature-list">
              <li>{t("features.measure")}</li>
              <li>{t("features.create_surveys")}</li>
              <li>{t("features.generate_report")}</li>
              <li>{t("features.improve_process")}</li>
            </ul>
          </div>
          <div className="image-content">
            <img src={quality} alt="Quality Illustration" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Quality;