import React, { useState } from "react";
import "../styles/navbar.css";
import logo from "../assets/images/edoceoLogo.png";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation("navbar");

  const toggleLanguage = () => {
    const newLang = i18n.language === "en" ? "fr" : "en";
    i18n.changeLanguage(newLang);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      {/* Logo */}
      <div className="navbar-brand">
        <Link to="/" onClick={() => setIsOpen(false)}>
          <img src={logo} alt="edoceo logo" />
        </Link>
      </div>

      {/* Navigation Links */}
      <div className={`navbar-links ${isOpen ? "mobile active" : ""}`}>
        <Link to="/" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>{t("home")}</span>
          </div>
        </Link>

        <Link to="/about" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>{t("about")}</span>
          </div>
        </Link>

        <Link to="/formation" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>{t("formations")}</span>
          </div>
        </Link>

        <Link to="/contact" onClick={() => setIsOpen(false)}>
          <div className="nav-item">
            <span>{t("contact")}</span>
          </div>
        </Link>
      </div>

      {/* Language Toggle */}
      <div className="toggle-container">
        <span>{t("EN")}</span>
        <label className="toggle-switch">
          <input
            type="checkbox"
            checked={i18n.language === "fr"}
            onChange={toggleLanguage}
          />
          <span className="slider"></span>
        </label>
        <span>{t("FR")}</span>
      </div>

      {/* Hamburger Menu */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </div>
    </nav>
  );
};

export default Navbar;