import React from "react";
import "../styles/presentation.css";
import plans from "../assets/images/co-work.png";
import { useTranslation } from "react-i18next";

const CompanyLaunch = () => {
  const { t, i18n } = useTranslation("presentation");

  return (
    <div className="max-width">
      <div className="company-launch-container">
        <div className="text-section">
          <h1>
            {t("title")}
            <br />
          </h1>
          <p>{t("description")}</p>
           <a href="#" className="button">
            {t("contact")}
          </a>
        </div>
        <div className="image-section">
          <img src={plans} alt="Team discussing plans" className="team-image" />
        </div>
      </div>
    </div>
  );
};

export default CompanyLaunch;
